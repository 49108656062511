import React from "react";

const Footer=()=>{

    return(
        <div>
            <div className="text-center py-3">
                <h3 className="text-center ">Created by <span className="text-red-600 text-bold">Sumit Sharma</span> | &copy; 2023</h3>
            </div> 

        </div>

    )
}
export default Footer;