
const Serviceapi=[
    {
        id:1,
        servicename:"UX/UI Design ",
        discriptions:"Here, We design for web application and app development for design using html,css, javascript and we use some external some application use Figma, invision,Axue tools use."
    },
    {
        id:2,
        servicename:"Web Development",
        discriptions:"Here, we design a custom application and responsive design for this web app cross plateform design for all system. "
    },
    {
        id:3,
        servicename:"App Development",
        discriptions:"Here, we design a custom application and responsive design for this web app cross plateform design for all system."
    },
    {
        id:4,
        servicename:"Database Development",
        discriptions:"here, we all create a database design and  we develop dynamic web application."
    },

]

export default Serviceapi